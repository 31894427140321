import React from 'react';
import Loadable from 'react-loadable';
import PublicProvider from '@/components/providers/PublicProvider';
import { LoadingPage } from '@/components/loading/loadingBase';
import HeaderMetadata from '@/layouts/HeaderMetadata';

const VerifyLoadable = Loadable({
  loader: () => import('@/sections/Verify'),
  loading: (props) => <LoadingPage isLoading={true} error={props.error} />,
});
const VerifyPage: React.FC = () => {
  return (
    <React.Fragment>
      <HeaderMetadata title="Verify" />
      <PublicProvider>
        <VerifyLoadable />
      </PublicProvider>
    </React.Fragment>
  );
};

export default VerifyPage;
